export const experience = [
  {
    company: "Coinbase",
    location: "New York, NY",
    from: "Apr '21",
    to: "Apr '24",
    title: "Software Engineer",
    tech: "Technologies: TypeScript, React, React Query, Ruby, Go, Amplify, Datadog, Codeflow",
    description: [
      "Architected and implemented a comprehensive mobile-web optimization of the Coinbase Developer Platform, which increased mobile engagement by a 417% over 3 months.",
      "Architected and implemented Coinbase’s first fully automated synthetic browser signup tests. Led cross-functional integrations with security, identity, and infrastructure teams to streamline test implementation; wrote code spanning the API, service, and config layers. Reduced signup-related incident detection times from hours/days to minutes.",
      "Architected and implemented the Coinbase Developer Platform Metrics frontend framework. Wrote custom hooks and views that enabled any product team generating time-series data to display performant charts with zero configuration.",
    ],
  },
  {
    company: "Blink Health",
    location: "New York, NY",
    from: "Dec '18",
    to: "Mar '20",
    title: "Software Engineer",
    summary:
      "Blink Health's goal is to make prescription medication affordable for everyone",
    tech: "Technologies: React/Redux, SWR, Rest Hooks, Material-UI, Formik, Django, DjangoRestFramework, Celery, jFrog, HTML, CSS, SQL",
    description: [
      "Part of a three person team selected to build the entire consumer-facing prescription management web application.  The app was optimized for mobile, included site-wide event tracking and analytics, and drove 15% of the company’s online traffic",
      "Expanded and re-architected the core internal pharmacy software for optimal performance and scalability: migrated the UI’s state management from Redux to SWR and Rest Hooks, abstracted boilerplate form logic into Formik, and extended session and authentication management across multiple browser tabs",
      "Integrated an internal Amazon SQS messaging library which enabled reliable communication between multiple instances of our backend; wrote code that spanned the API, service, and data layers, along with a full suite of unit and integration tests",
      "Provided on-call support in staging and production, leveraging analytic tools to fix bugs using SumoLogic, database tunneling, and command-line tooling",
    ],
  },
  {
    company: "Simplifeye",
    location: "New York, NY",
    from: "Apr '18",
    to: "Nov '18",
    title: "Software Engineer",
    summary:
      "Simplifeye is a health-care communication startup that helps practices acquire, retain, and communicate with patients",
    tech: "Technologies: React/Redux, Material-UI, HTML, CSS, Sass,TypeScript & NodeJS",
    description: [
      "Built a secure, internal conversation management center integrated with web-sockets and a NodeJS API, consolidated critical practice information into a unified view and doubled the amount of conversations chat specialists could conduct at once",
      "Shipped a patient-facing, cross-browser compliant messaging app that could be seamlessly embedded into any doctor’s website with one line of JavaScript",
      "Owned and implemented an application - wide upgrade of all Material - UI React components from V-0.20 to V-1.2 writing custom themes and Sass partials which ensured consistent branding and decreased time spent on styling by 20 % per project",
      "Coordinated with our customer success team to extend an internal application to optimize location, staff, and permission management, which reduced customer on-boarding time by 1-2 days",
    ],
  },
  {
    company: "Morgan Stanley",
    location: "New York, NY",
    from: "May '14",
    to: "Feb '17",
    title: "Private Wealth Manager",
    summary: "",
    tech: "",
    description: [
      "Built a 21 million dollar wealth management practice with a 100% client retention rate",
      "Selected for the wealth advisory financial modeling program where I was ranked in the top 8% of my North American peer group",
      "Worked side by side with financial advisors in the company’s largest wealth management complex in the world, generating a 12 % annual increase in advisor revenue on average",
    ],
  },
];
